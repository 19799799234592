<template lang="pug">
b-row
  b-col(cols="3")
    InventoryMapFilters(@change="updateFilter")
  b-col(cols="9")
    FilteredMap(:filters="filters")
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import FilteredMap from './FilteredMap.vue'
import InventoryMapFilters from './InventoryMapFilters.vue'

export default {
  name: 'InventoryMap',
  components: {
    FilteredMap,
    InventoryMapFilters,
    BRow,
    BCol,
  },
  data: () => ({ filters: null }),
  created() {
  },
  methods: {
    updateFilter(filters) {
      this.filters = filters
    },

  },
}
</script>

<style scoped lang="scss">
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.dark-layout {
  .bg-white {
    background-color: $theme-dark-body-bg !important;
  }
}
</style>
