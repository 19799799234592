<template lang="pug">
#InventoryMapFilters
  //- :value='toValueForPrice(currentRefinement, range)'
  //- @change='customRefineForPrice({ min:$event[0], max:$event[1]}, range, refine)'
  h6.filter-title.ml-1.mt-2.mb-4
    | Price
  vue-slider.ml-2.mr-2.mb-3(
    v-model="filters.price"
    tooltip="always"
    :tooltip-formatter="v => `$${v / 1000}k`"
    :tooltip-placement="['top', 'bottom']"
    :min='minPrice'
    :max='maxPrice'

    )
  h6.filter-title.ml-1.mt-2.mb-4
    | Frontage
  vue-slider.ml-2.mr-2.mt-6th(
    v-model="filters.frontage"
    tooltip="always"
    :tooltip-placement="['top', 'bottom']"
    :tooltip-formatter="v => `${v} m`"
    :min='minFrontage'
    :max='maxFrontage'

    )

  //- :lazy='true'
</template>

<script>
import VueSlider from 'vue-slider-component'
import '@/@core/scss/vue/libs/vue-slider.scss'

export default {
  name: 'InventoryMapFilters',
  components: { VueSlider },
  data: () => ({
    minPrice: 300e3,
    maxPrice: 500e3,
    minFrontage: 0,
    maxFrontage: 40,
    filters: {
      price: [350e3, 450e3],
      frontage: [10, 20],
    },
  }),
  // computed:{
  //   filter() {
  //     return {
  //       price: this.price,
  //     }
  //   }
  // },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.$emit('change', this.filters)
      },
    },
  },
  created() {
    window.InventoryMapFilters = this
  },
}
</script>

<style scoped>

</style>
